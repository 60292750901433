<template>
  <v-navigation-drawer app v-model="drawerInternal" color="#EBEBEB">
    <v-list nav dense>
      <v-subheader>Menu</v-subheader>
      <v-list-item @click="goHome">
        <v-list-item-content>
          <v-list-item-title><v-icon class="pr-1">mdi-home</v-icon>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="goToRules">
        <v-list-item-content>
          <v-list-item-title><v-icon class="pr-1">mdi-format-list-checks</v-icon>Rules</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-if="getNumberOfLeagues > 1">
        <v-subheader>League Picker</v-subheader>

        <v-list-item v-for="(leagueId, i) in getUserLeagueIds" :key="leagueId"
          @click="viewLeague(leagueId)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{i+1}}. {{ getUserLeagues[leagueId]  }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </template>

<!--      <v-icon class="pr-1">mdi-shield</v-icon>-->
      <!-- end league-select context-->

      <template v-if="getLeagueId">
        <v-subheader >{{getLeague.name}}</v-subheader>

        <v-list-item v-if="isLeagueAdmin" @click="goToLeagueAdmin">
          <v-list-item-content>
            <v-list-item-title><v-icon class="pr-1">mdi-shield</v-icon>Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="goToLeagueDashboard">
          <v-list-item-content>
            <v-list-item-title><v-icon class="pr-1">mdi-view-dashboard</v-icon>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="goToDetailedPicks">
          <v-list-item-content>
            <v-list-item-title><v-icon class="pr-1">mdi-table</v-icon>Detailed Picks</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="goToEntries">
          <v-list-item-content>
            <v-list-item-title><v-icon class="pr-1">mdi-account-group</v-icon>Entries</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="goToTrophies">
          <v-list-item-content>
            <v-list-item-title><v-icon class="pr-1">mdi-trophy</v-icon>Hall Of Champions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!--      end league context-->
      <template v-if="isGlobalAdmin">
        <v-subheader >Global Admin</v-subheader>

        <v-list-item @click="goToGlobalAdmin">
          <v-list-item-content>
            <v-list-item-title><v-icon class="pr-1">mdi-shield</v-icon>Global Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!--      end admin context-->

      <template>
        <v-subheader>User</v-subheader>
        <v-list-item @click="goToMyUser">
          <v-list-item-content>
            <v-list-item-title><v-icon class="pr-1">mdi-account</v-icon>My User</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

    </v-list>
  </v-navigation-drawer>
</template>
<script>
import * as routes from '@/router/route-names'
import {mapGetters} from 'vuex'

export default {
  // If you need these in the template
  // created() {
  //   this.ROUTES = routes
  // },
  props: {
    drawer: {type: Boolean, required: false}
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      loggedInUser: 'getLoggedInUser',
      getLeague: 'getLeague',
      getLeagueId: 'getLeagueId',
      isGlobalAdmin: 'isGlobalAdmin',
      getUserLeagueIds: 'getUserLeagueIds',
      getNumberOfLeagues: 'getNumberOfLeagues',
      getUserLeagues: 'getUserLeagues',
    }),
    drawerInternal: {
      get() {
        return this.drawer
      },
      set(newValue) {
        this.$emit('update-drawer', newValue)
      }
    },

    isLeagueAdmin () {
      if (!this.getLeague) return false
      return this.getLeague.isAnAdmin
    },
    // baseUrl () {
    //   return process.env.VUE_APP_API_BASE_URL
    // },
    // logoutUrl () {
    //   return `${this.baseUrl}/oauth2/authorization/google`
    // }
  },
  methods: {
    signOut () {
      console.log('TODO: signOut()')
    },
    goHome () {
      this.$router.push({
        name: routes.HOME,
      }).catch(()=>{});
    },
    goToRules () {
      this.$router.push({
        name: routes.RULES,
      }).catch(()=>{});
    },
    goToMyUser () {
      this.$router.push({
        name: routes.MY_USER,
      }).catch(()=>{});
    },
    goToLeagueDashboard () {
      this.$router.push({
        name: routes.LEAGUE,
        params: {leagueId: this.getLeagueId}
      }).catch(()=>{});
    },
    goToDetailedPicks () {
      this.$router.push({
        name: routes.LEAGUE_PICKS,
        params: {leagueId: this.getLeagueId}
      }).catch(() => {})
    },
    goToEntries () {
      this.$router.push({
        name: routes.LEAGUE_ENTRIES,
        params: {leagueId: this.getLeagueId}
      }).catch(() => {})
    },
    goToLeagueAdmin () {
      this.$router.push({
        name: routes.LEAGUE_ADMIN,
        params: {leagueId: this.getLeagueId}
      }).catch(() => {})
    },
    goToGlobalAdmin () {
      this.$router.push({
        name: routes.GLOBAL_ADMIN,
      }).catch(() => {})
    },
    goToTrophies () {
      this.$router.push({
        name: routes.LEAGUE_CHAMPS,
        params: {leagueId: this.getLeagueId}
      }).catch(() => {})
    },
    viewLeague (leagueId) {
      this.$router.push({
        name: routes.LEAGUE,
        params: { leagueId: leagueId}
      }).catch(()=>{});
    },
    async logout () {
      try {
        await this.$delete('')
      } catch (ex) {
        console.error(ex)
      }
    }
  }
}
</script>
